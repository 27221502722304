<template>
  <div class="translation_box">
    <!-- 文档翻译 -->
    <el-container v-bind:style="{ height: Height + 'px' }">
      <el-main v-bind:style="{ minHeight: Height + 'px' }">
        <UPLOAD v-if="keys == '1'"></UPLOAD>
        <div v-if="keys == '2'">
          <REPORT></REPORT>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import UPLOAD from "./component/upload.vue";
import REPORT from "./component/report.vue";
import { applet_login } from "@/Api/translation";
export default {
  data() {
    return {
      Height: 0,
      keys: "1",
      activeIndex: "1",
    };
  },
  mounted() {
    //动态设置内容高度 让footer始终居底   header+footer的高度是190
    // console.log(this.name)
    this.Height = document.documentElement.clientHeight - 80; //监听浏览器窗口变化
    window.onresize = () => {
      this.Height = document.documentElement.clientHeight - 80;
    };
  },
  created() {
    if (this.$route.query.webPage) {
      this.keys = this.$route.query.webPage.toString();
      this.activeIndex = this.$route.query.webPage.toString();
    }
    this.loginAutomatic();
    // this.unreads();
    // if (window.sessionStorage.getItem("webPage")) {
    //   this.keys = window.sessionStorage.getItem("webPage");
    //   // console.log(this.keys)
    // } else {
    //   this.keys = "1";
    //   window.sessionStorage.setItem("webPage", this.keys);
    // }
  },
  // 监听路由变化
  watch: {
    $route(to, from) {
      if (to.query.webPage) {
        this.keys = to.query.webPage.toString();
      }
    },
  },
  methods: {
    loginAutomatic() {
      var tmp = Date.parse(new Date()).toString();
      tmp = tmp.substr(0, 10);

      let event = localStorage.getItem("code") + "|" + tmp;
      // console.log(event);
      let codesid = {
        code: event,
      };

      applet_login(codesid)
        .then((res) => {
          // 提交成功 弹出提示框并刷新页面
          console.log(res);
          if (res.data.status == 1) {
          } else {
            if (
              res.data.data &&
              res.data.data.token &&
              res.data.data.token.token
            ) {
              localStorage.setItem("token_USID", res.data.data.token.token);
            }
          }
          if (res.status == 200) {
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleSelect(key) {
      this.keys = key;
      this.$router.push({
        name: "Translation",
        query: { webPage: key },
      });
      // if(this.$route.query.search){
      //   this.$router.push({
      //     path: "/academic",
      //   });
      // }
      // window.sessionStorage.setItem("webPage", this.keys);
    },
  },
  components: {
    UPLOAD,
    REPORT,
  },
};
</script>

<style lang="less" scoped>
.translation_box {
  .el-aside {
    background-color: #fff;
    color: #333;
    text-align: center;
    line-height: 200px;
  }

  .el-main {
    background-color: #e9eef3;
    color: #333;
  }

  body > .el-container {
    margin-bottom: 40px;
  }

  .el-container:nth-child(5) .el-aside,
  .el-container:nth-child(6) .el-aside {
    line-height: 260px;
  }

  .el-container:nth-child(7) .el-aside {
    line-height: 320px;
  }

  .el-menu {
    background-color: #fff;
  }
}

.is-active div {
  background-color: #b14a75;

  span {
    color: #fff;
  }

  i {
    color: #fff;
  }
}

.el-menu-item {
  padding: 5px 50px !important;
  background-color: #fff;
  margin-bottom: 5px;
}

.aside_item {
  width: 100%;
  height: 50px;
  line-height: 50px;
  border-radius: 5px;

  span {
    color: #b1aec8;
    font-size: 15px;
  }
}

.item {
  line-height: 30px;

  /deep/ .el-badge__content.is-fixed {
    position: absolute;
    top: 5px;
    right: 13px;
    transform: translateY(-50%) translateX(100%);
  }
}

.item {
  line-height: 30px;

  /deep/ .el-badge__content.is-fixed {
    position: absolute;
    top: 0px;
    right: 13px;
    transform: translateY(-50%) translateX(100%);
  }
}
</style>